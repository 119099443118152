import { type ChangeEvent, type FC } from 'react'
import { Currency, displayCurrency, getCurrencySymbol } from '@lib/services'
import { Input } from '@lib/components'
import { type Cause, type Plan, calculateDailyAmounts } from '@shamaazi/mytennights'
import { MytennightsTestId } from '@lib/testing'

interface Props {
  plan: Plan
  cause: Cause
  value: number
  onChange: (amount: number) => void
  trackEvent: () => void
}

const CauseCard: FC<Props> = ({ plan, cause, value, onChange, trackEvent }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = Math.round(Number(e.target.value ?? 0) * 100)
    if (value < 0 || value > 99999999) {
      return
    }
    onChange(value)
  }

  const { dailyAmount, arafahAmount } = calculateDailyAmounts(plan.split, plan.multiplier, plan.startDateOffset, value)

  return <div className="relative flex flex-col gap-3 px-4 py-4 bg-white border shadow-lg rounded-xl border-mtd-gray-100">
    <h2 className="text-xl font-bold text-mtd-blue-900">{cause.title}</h2>
    <div>{cause.description}</div>
    <div className="relative">
      <div className="absolute flex items-center h-full ml-5 text-mtd-blue-900">{getCurrencySymbol(plan?.charity?.currency ?? Currency.GBP)}</div>
      <Input
        variant="mtd"
        data-test-id={MytennightsTestId.causeInput_ + cause.title.toLowerCase()}
        className={`rounded-full ${getCurrencySymbol(plan?.charity?.currency).length > 1 ? 'pl-14' : 'pl-10'}`}
        aria-label="Cause amount"
        placeholder="0.00"
        inputMode="decimal"
        value={value > 0 ? value / 100 : ''}
        onChange={handleChange}
        onBlur={trackEvent}
        type="number"
      />
    </div>
    <div>
      {displayCurrency({ amount: dailyAmount ?? 0, currency: plan?.charity?.currency ?? Currency.GBP })} per day
      {plan.split === 'more-arafah' ? ` (${displayCurrency({ amount: arafahAmount, currency: plan?.charity?.currency ?? Currency.GBP })} on Arafah)` : ''}
    </div>
  </div>
}

export default CauseCard
