import { type ChangeEvent, type FC, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { useAuth } from '@lib/hooks'
import { CircleChecked, CircleCrossed, Page, Spinner } from '@lib/components'
import {
  AccountTabs,
  Card,
  getMarketingPreferences,
  type Preference,
  type Preferences,
  updateMarketingPreference,
  type UpdateMarketingPreferenceTemplate,
  Variant,
  MTDtracking,
  getChannelValue
} from '@shamaazi/mytennights'
import { MarketingChannel } from '@lib/services'

export const MarketingPreferences: FC = () => {
  const [preferences, setPreferences] = useState<Preferences>({ email: { channels: {}, charity_names: {} } })
  const [showUpdatedConfirmation, setShowUpdatedConfirmation] = useState(false)
  const [showUpdatedError, setShowUpdatedError] = useState(false)
  const { user } = useAuth()

  const fetchMarketingPreferences = async (): Promise<void> => {
    const res = await getMarketingPreferences()
    setPreferences(res)
  }

  const queryClient = useQueryClient()

  const { isLoading, isError } = useQuery('preferences', fetchMarketingPreferences, { refetchOnWindowFocus: false })

  const { mutate: updatePreference, isLoading: isUpdating } = useMutation(async (data: UpdateMarketingPreferenceTemplate): Promise<Preference> => {
    return await updateMarketingPreference(data)
  }, {
    onSuccess: (_, data: UpdateMarketingPreferenceTemplate) => {
      MTDtracking.preferencesUpdated(user?.email, {
        hearFromUs: getChannelValue(data, MarketingChannel.updates),
        productUpdates: getChannelValue(data, MarketingChannel.product),
        charity: data.source === 'charity' ? data.channel : undefined,
        charityValue: data.source === 'charity' ? data.value : undefined
      })
      queryClient.invalidateQueries('preferences').catch(() => {})
      setShowUpdatedConfirmation(true)
      setTimeout(() => setShowUpdatedConfirmation(false), 2000)
    },
    onError: () => {
      setShowUpdatedError(true)
      setTimeout(() => setShowUpdatedError(false), 2000)
    }
  })

  return <Page skinny className="mt-10">
    <AccountTabs active="preferences" variant={Variant.mtd} tracking={MTDtracking} />

    {showUpdatedConfirmation && <Card variant="mtd" className="fixed inset-x-0 z-10 max-w-lg p-4 mx-auto text-center bg-white top-24 lg:top-36">
      <span className="mr-2">Your preferences have been updated</span>
      <CircleChecked className="inline-block align-text-bottom text-mtd-blue" />
    </Card>}

    {showUpdatedError && <Card variant="mtd" className="fixed inset-x-0 z-10 max-w-lg p-4 mx-auto text-center bg-white top-24 lg:top-36">
      <span className="mr-2">Sorry, we were unable to update your preferences</span>
      <CircleCrossed className="inline-block align-text-bottom text-mtd-red" />
    </Card>}

    {(isLoading) && <Spinner className="mx-auto text-mtd-blue" />}

    {isError && <p className="font-medium text-mtd-red">
      Sorry, we weren't able to load your marketing preferences. <br />Please try again,
      and if the issue persists, <Link className="underline" to="/contact">contact us</Link>.
    </p>}

    {!isLoading && !isError && <div>
      <Card variant="mtd" className="mx-4 my-8 font-medium bg-white">
        <section className="p-10 border-b border-mtd-gray-200">
          <h2 className="text-lg text-mtd-blue-900">MyTenDays Preferences</h2>
          <p className="mt-4 text-sm">I am happy to be contacted by MyTenDays for the following purposes.</p>
        </section>

        <section className="p-10 border-b border-mtd-gray-200">
          <label className="flex items-center mt-4">
            <input
              className={`mr-4 ${isUpdating ? 'animate-pulse' : ''}`}
              type="checkbox"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updatePreference({
                  transport: 'email',
                  identifier: user?.email ?? '',
                  channel: 'updates',
                  source: 'mytendays',
                  value: e.target.checked
                })
              }}
              checked={preferences?.email?.channels?.mytendays?.updates ?? false}
              disabled={isUpdating} />
            Be the first to know about MyTenDays' causes, updates and news
          </label>
        </section>

        <section className="p-10 border-b border-mtd-gray-200">
          <label className="flex items-center mt-4">
            <input
              className={`mr-4 ${isUpdating ? 'animate-pulse' : ''}`}
              type="checkbox"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updatePreference({
                  transport: 'email',
                  identifier: user?.email ?? '',
                  channel: 'product',
                  source: 'shamaazi',
                  value: e.target.checked
                })
              }}
              checked={preferences?.email?.channels?.shamaazi?.product ?? false}
              disabled={isUpdating} />
            Receive updates about products we build so you can use tech to get closer to Allah
          </label>
        </section>
      </Card>

      <Card variant="mtd" className="mx-4 my-8 font-medium bg-white">
        <section className="p-10 border-b border-mtd-gray-200">
          <h2 className="text-lg text-mtd-blue-900">Charity Preferences</h2>
          <p className="mt-4 text-sm">I am happy to be contacted by these charities with information on their projects, fundraising activities and appeals via email.</p>
        </section>

        <section className="p-10 border-b border-mtd-gray-200">
          {Object.keys(preferences?.email.channels.charity ?? {}).length === 0 && <p className="text-sm">
            It looks like you haven't donated to any charities yet.<br />
            If this incorrect and you wish to update your preferences please <Link className="underline" to="/contact">contact us</Link>.
          </p>}

          {Object.keys(preferences?.email.channels.charity ?? {}).map(charityID => {
            return <label key={charityID} className="flex items-center mt-4">
              <input
                className={`mr-4 ${isUpdating ? 'animate-pulse' : ''}`}
                type="checkbox"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updatePreference({
                    transport: 'email',
                    identifier: user?.email ?? '',
                    channel: charityID,
                    source: 'charity',
                    value: e.target.checked
                  })
                }}
                checked={preferences?.email?.channels?.charity[charityID] ?? false}
                disabled={isUpdating} />
              {preferences?.email.charity_names[charityID]}
            </label>
          })}
        </section>
      </Card>

    </div>}
  </Page>
}
