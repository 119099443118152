/* eslint-disable */
// @ts-nocheck
export const gaSnippet = () => {
  (function (i, s, o, g, r, a, m) {
    i.GoogleAnalyticsObject = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')

  ga('create', getGAKey(), 'auto')
  ga('require', 'ecommerce')
  ga('send', 'pageview')
}
/* eslint-enable */

const getGAKey = (): string => {
  const testKey = 'UA-189645571-5'
  const prodKey = 'UA-189645571-4'

  if (document.location.hostname.endsWith('mytendays.com')) {
    return prodKey
  }

  return testKey
}
