import { type ChangeEvent, type FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { ActiveCampaign, type CharityCampaign, loadHomepageCharityCampaigns, useCheckoutPlan, MTDtracking } from '@shamaazi/mytennights'
import { CountrySelection, Input, Spinner, TaxDeductibleDisclaimer, SearchIcon } from '@lib/components'
import { CharityCard } from '~/components/CharityCard'
import { Country, isMultiCharity } from '@lib/services'
import { CheckoutProgress, CheckoutStep } from '~/components/CheckoutProgress'
import { useAuth } from '@lib/hooks'

export const Charities: FC<{ nextPage: string }> = ({ nextPage }) => {
  const [country, setCountry] = useState<Country>(Country.GB)
  const [searchState, setSearchState] = useState('')
  const history = useHistory()
  const { plan, setPlan } = useCheckoutPlan()
  const { isLoading, isError, data } = useQuery('charities', async () => loadHomepageCharityCampaigns(ActiveCampaign.myTenDays))
  const { user } = useAuth()

  const onSelect = (charity: CharityCampaign): void => {
    MTDtracking.selectCharity(user?.email, charity.charity_name, charity.charity_id)
    const causes = plan.charity.charity_id === charity.charity_id ? plan.causes : {}
    setPlan({ ...plan, charity, causes })
    history.push(nextPage)
  }

  const onChangeCountry = (country: Country): void => {
    MTDtracking.countryChange(user?.email, country)
    setCountry(country)
  }
  const onSearchTextChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchState(e.target.value)
  }

  const filterCharities = (charity: CharityCampaign): boolean => {
    const searchTerm = searchState.trim().toLowerCase()
    return charity.charity_name.toLowerCase().includes(searchTerm) || charity.charity_id.toLowerCase().includes(searchTerm) ||
      charity.causes?.find(c => c.title.toLowerCase().includes(searchTerm)) !== undefined
  }

  const charitiesInSelectedCountry = data?.filter(c => c.country === country) ?? []
  let charities: CharityCampaign[]
  if (plan.chainCodeCharity && plan.chainCodeCharity.country === country) {
    const multiCharity = charitiesInSelectedCountry.find((c) => isMultiCharity(c.charity_id))
    const friendCharity = plan.chainCodeCharity
    let remainingCharities: CharityCampaign[] = []
    if (friendCharity && multiCharity) {
      remainingCharities = charitiesInSelectedCountry.filter(c => c.charity_id !== multiCharity.charity_id).filter(c => c.charity_id !== friendCharity.charity_id)
    }
    charities = [multiCharity ?? friendCharity]
    if (multiCharity && multiCharity.charity_id !== friendCharity.charity_id) {
      charities.push(friendCharity)
    }
    charities.push(...remainingCharities)
  } else {
    charities = charitiesInSelectedCountry
  }
  const countries = [...new Set(data?.map(c => c.country) ?? null)]

  const charityCards = (charities ?? []).filter(filterCharities).map(c => {
    return <CharityCard
      key={c?.charity_id ?? ''}
      charityID={c?.charity_id ?? ''}
      charityName={c?.charity_name ?? ''}
      onClick={() => onSelect(c)}
    />
  })

  return <main>
    <CheckoutProgress currentStep={CheckoutStep.Charity} />
    <div className="max-w-4xl px-4 mx-auto">
      <div className="mb-6">
        <h1 className="my-8 text-3xl font-bold text-mtd-blue-900">Choose your charity</h1>
      </div>

      <div className="w-full mb-8 sm:w-96 space-y-4">
        <label className="font-bold text-mtd-blue-900">Country</label>
        <CountrySelection
          countries={countries}
          onCountryChange={onChangeCountry}
          className="w-full max-w-full pl-12"
          value={country}
          variant="mtd"
        />
        <div className="relative">
          <SearchIcon />
          <Input variant="mtd" value={searchState} onChange={onSearchTextChanged}
                 placeholder="Find a charity or cause" className="w-full pl-12" />
        </div>
      </div>
      <TaxDeductibleDisclaimer />
      {isLoading && <Spinner className="mx-auto text-mtd-blue" />}
      {!isLoading && isError && <h2 className="heading-lg">Error</h2>}
      {!isLoading && (charities ?? []).length === 0 && <h2 className="heading-lg">No charities found</h2>}
      <div className="mt-5 mb-8 grid gap-4 sm:gap-8 sm:grid-cols-2 md:grid-cols-3">
        {(charities ?? []).length > 0 && charityCards}
      </div>
    </div>
  </main>
}
