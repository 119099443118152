import { type FC } from 'react'
import { Redirect } from 'react-router-dom'
import { getMinimumAmount, useCheckoutPlan, SplitType, getCausesTotal } from '@shamaazi/mytennights'
import { Currency } from '@lib/services'
import { Navigation } from '~/navigation/navigation'

export const CausesRedirect: FC = () => {
  const { plan } = useCheckoutPlan()

  const total = getCausesTotal(plan)
  const min = getMinimumAmount(plan?.charity?.currency ?? Currency.GBP)

  if ((min ?? 0) === 0 || (total ?? 0) === 0) {
    return <Redirect to={Navigation.selectCauses()} />
  }

  if (total < min) {
    return <Redirect to={Navigation.selectCauses()} />
  }

  return <></>
}

export const CharityRedirect: FC = () => {
  const { plan } = useCheckoutPlan()

  if ((plan.charity.charity_id ?? '') === '') {
    return <Redirect to={Navigation.selectCharity()} />
  }

  return <></>
}

export const HomeRedirect: FC = () => {
  const { plan } = useCheckoutPlan()

  if (plan.split === SplitType.NotSelected) {
    return <Redirect to={Navigation.home()} />
  }

  return <></>
}
