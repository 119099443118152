import { type FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { Button, CircleChecked, CircleExclamation, Page, Spinner } from '@lib/components'
import { displayCurrency } from '@lib/services'
import { Card, getStripe, loadSchedule, NotFoundError, PaymentStatus as Status, type Schedule as ScheduleType } from '@shamaazi/mytennights'
import { DateTime } from 'luxon'

import { MTDHeader } from '~/components/MTDHeader'

export const SchedulePayment: FC = () => {
  const { scheduleID, number } = useParams<{ scheduleID: string, number: string }>()
  const { isLoading, isError, error, data: schedule } = useQuery<ScheduleType>(['schedule', scheduleID], async () => await loadSchedule(scheduleID), { retry: 0 })
  const queryClient = useQueryClient()

  const payment = schedule?.payments?.find((n) => parseInt(number) === n.number)

  const { isLoading: retryLoading, isError: isRetryError, mutate: retryPayment } = useMutation(async (): Promise<string> => {
    const stripe = await getStripe(schedule!.charity_id)
    if (stripe === null) {
      throw new Error('Stripe object was not properly initialised')
    }
    const result = await stripe?.confirmCardPayment(payment?.paymentIntentSecret ?? '', {
      payment_method: schedule?.paymentMethod
    })

    if (result?.error != null) {
      throw new Error(result?.error?.message)
    }

    return result?.paymentIntent?.status ?? ''
  }, {
    onSuccess: (data) => {
      const payments = schedule?.payments?.map((p) => {
        return p.number !== parseInt(number) ? p : { ...p, status: data }
      })

      queryClient.setQueryData(['schedules', scheduleID], {
        ...schedule,
        payments
      })
    }
  })

  return <main>
    <MTDHeader />
    <div className="mx-4 my-6 underline">
      <Link to={`/account/schedule/${scheduleID}`}>&lt; Back to Schedule</Link>
    </div>
    <Page className="px-10 py-10 font-medium lg:grid lg:gap-x-20 lg:grid-cols-fr-auto">
      <div>
        <h1 className="text-xl text-mtd-blue">Payment #{number}</h1>
        {isLoading && <div className="flex justify-center mt-20">
          <Spinner />
        </div>}
        {isError && error instanceof NotFoundError && <div className="mt-10">
          <h2>Sorry, we couldn&apos;t find this donation.<br />Please check you are logged in as the correct email address</h2>
        </div>}
        {isError && !(error instanceof NotFoundError) && <div className="mt-10">
          <h2>Sorry, we weren't able to load your schedule. Please try again later and if
            the issue persists, <Link className="underline text-mtd-blue" to="/contact">contact us</Link>
          </h2>
        </div>}
        {!isLoading && !isError && schedule !== undefined && payment !== undefined && <>
          <p className="my-4 text-mtd-blue-900">{payment.date.toLocaleString(DateTime.DATE_MED)}</p>
          {payment.status === Status.Cancelled && <p className="flex items-center my-2 text-sm text-mtd-red"><CircleExclamation className="mr-4" /> Payment Cancelled</p>}
          {payment.status === Status.Failed && <p className="flex items-center my-2 text-sm text-mtd-red"><CircleExclamation className="mr-4" /> Payment Failed</p>}
          {payment.status === Status.Succeeded && <p className="flex items-center my-2 text-sm text-mtd-blue"><CircleChecked className="mr-4" /> Payment succeeded</p>}
          {payment.status !== Status.Failed && payment.status !== Status.Succeeded && payment.status !== Status.Cancelled &&
            <span className="flex items-center my-3 text-sm"><CircleExclamation className="mr-4" /> Payment {payment.status}</span>}

          <hr className="mt-10 text-mtd-gray-200" />
          <section className="items-center my-10 grid gap-y-6 gap-x-10 grid-cols-auto-fr">
            <span className="text-sm">Charity</span>
            <span className="text-right text-mtd-blue-900">{schedule.charity_name}</span>
            <span className="self-start mt-2 text-sm">Causes</span>
            <div>
              {payment.breakdown.causes.map(c => <div key={c.name} className="mt-2 grid gap-x-6 grid-cols-fr-auto">
                <span className="text-right">{c.name}</span>
                <span className="text-right text-mtd-blue-900">{displayCurrency({ amount: c.amount, currency: schedule.currency })}</span>
              </div>)}
            </div>
            <span className="text-sm">MyTenDays Donation</span>
            <span className="text-right text-mtd-blue-900">{displayCurrency({ amount: payment.breakdown.tip, currency: schedule.currency })}</span>
            <span className="text-sm">Transaction Fees</span>
            <span className="text-right text-mtd-blue-900">{displayCurrency({ amount: schedule.payFees ? payment.breakdown.fee : 0, currency: schedule.currency })}</span>
            <hr className="col-span-2 text-mtd-gray-200" />
            <span className="text-sm">Total</span>
            <span className="font-bold text-right text-mtd-blue-900">{displayCurrency({ amount: payment.total, currency: schedule.currency })}</span>
            <hr className="col-span-2 text-mtd-gray-200" />
          </section>
        </>}
      </div>
      <div className="flex flex-col">
        <Card variant="mtd" className="mt-20 -mx-6 lg:mx-0 lg:w-96 md:mt-0">
          {isLoading && <section className="flex items-center justify-center p-7"><Spinner /></section>}
          {!isLoading && !isError && schedule !== undefined && <>
            {schedule.cardBrand !== '' && <section className="p-7">
              <div>Paying with card: {schedule.cardBrand} ending {schedule.last4CardDigits}</div>
              <div className="mt-3">Expires {schedule.cardExpiry}</div>
            </section>}
            {schedule.cardBrand === '' && <section className="p-7">
              <div>Paying with unknown card</div>
            </section>}
            {schedule.actionable && schedule.cardBrand !== '' && payment?.status === Status.Failed && <section className="border-t p-7 border-mtd-gray-200">
              <div className="flex justify-center">
                <Button disabled={retryLoading} loading={retryLoading} onClick={() => retryPayment()} variant="mtd-primary">Retry Payment</Button>
              </div>
              {isRetryError && <p className="mt-3 text-sm text-mtd-red">
                Sorry, we were unable to complete your payment. Please try again and if the issue persists <Link className="underline" to="/contact">
                contact us.
              </Link>
              </p>}
            </section>}
          </>}
        </Card>
      </div>
    </Page>
  </main>
}
