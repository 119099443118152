import { Login } from '@lib/legacycomponents'
import { MTDHeader } from '~/components/MTDHeader'
import { Page } from '@lib/components'
import { MTDtracking } from '@shamaazi/mytennights'
import { type FC } from 'react'

export const LoginPage: FC = () => {
  return <main>
    <MTDHeader />
    <Page skinny className="px-4 my-6 column stack">
      <h1 className="heading-lg">Login</h1>
      <p>Already made a donation?<br />Login to check your payment schedule or your preferences.</p>

      <Login contactEmail="contact@mytendays.com" onEnterEmail={(e: string) => MTDtracking.signInEmail(e)} onEnterCode={(e: string) => MTDtracking.signInCode(e)} />

    </Page>
  </main>
}
