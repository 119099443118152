import { type FC } from 'react'
import { EmailXL, Page, PhoneXL } from '@lib/components'
import { MTDHeader } from '~/components'

export const Contact: FC = () => {
  return <main>
    <MTDHeader overlay />
    <section className="bg-top bg-cover min-h-600 clouds">
      <Page skinny className="px-4 py-24 font-medium text-mtd-blue-900 min-h-600 md:min-h-screen">
        <h1 className="text-2xl font-bold md:text-4xl">
          Need some help?
        </h1>
        <p className="mt-2 md:text-xl md:mt-4">
          Here's how you can get in touch.
        </p>

        <div className="bg-mtd-blue-900 my-8 h-0.5 md:my-12"></div>

        <div className="flex items-center">
          <span className="inline-block text-white rounded-full bg-mtd-blue-900">
            <EmailXL />
          </span>
          <div className="flex-1 mt-2 ml-4 md:ml-8">
            <p><strong>Email us</strong> for general enquiries, marketing &#38; partnership.</p>
            <p className="mt-2 font-bold"><a href="mailto:contact@mytendays.com">contact@mytendays.com</a></p>
          </div>
        </div>

        <div className="flex items-center mt-8">
          <span className="inline-block text-white rounded-full bg-mtd-blue-900">
            <PhoneXL />
          </span>
          <div className="flex-1 mt-2 ml-4 md:ml-8">
            <p><strong>Call us</strong> for customer service support.</p>
            <p className="mt-2 font-bold">(+44) 020 4541 6388</p>
          </div>
        </div>
      </Page>
    </section>
  </main>
}
