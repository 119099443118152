import Copy from '~/components/Copy'
import success from '~/assets/icons/success.svg'
import twitterLogo from '~/assets/icons/twitter.svg'
import whatsAppLogo from '~/assets/icons/whatsapp.svg'
import facebookLogo from '~/assets/icons/facebook.svg'
import emailLogo from '~/assets/icons/email.svg'
import copyLogo from '~/assets/icons/copy.svg'
import { type FC } from 'react'
import { MTDtracking } from '@shamaazi/mytennights'
import { useAuth } from '@lib/hooks'

interface ShareButtonProps {
  url: string
  imageSrc: string
  label: string
}

const ShareButton: FC<ShareButtonProps> = ({ url, imageSrc, label }) => {
  const { user } = useAuth()

  return <a href={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => MTDtracking.socialShareButton(user?.email, label, false, url)}>
    <img
      src={imageSrc}
      alt={label}
    />
  </a>
}

export const ShareContent: FC = () => {
  const message = 'I’m all set for the ten days of Hajj! \n\nOn MyTenDays, I automated my donations across the ten days of Hajj, the most rewarding days of the year.\n\n You can catch the blessed days too here: \n'

  const twitterShareLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}&url=${encodeURIComponent('mytendays.co/twt')}`
  const facebookShareLink = `https://www.facebook.com/share.php?u=${encodeURIComponent('mytendays.co/fb')}&quote=${encodeURIComponent(message)}`
  const whatsAppShareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}${encodeURIComponent('mytendays.co/wa')}`
  const emailShareLink = `mailto:?subject=My Ten Days&body=${message} ${'mytendays.co/email'}`

  return <div className="flex flex-col items-center justify-center min-h-screen clouds">
    <div className="bg-white shadow-lg rounded-xl">
      <div className="justify-between py-2 mx-4 my-6 content column stack">
        <div className="items-center row">
          <img className="mx-4" src={success} alt="success tick" />
          <h4 className="flex-grow text-center heading-sm">You&apos;re all set for the blessed days</h4>
        </div>
        <hr />
        <h3 className="text-center heading-md">Now help your friends maximise their reward too</h3>
        <div className="justify-around row">
          <ShareButton
            url={whatsAppShareLink}
            imageSrc={whatsAppLogo}
            label="whatsapp"
          />
          <Copy copyContent="mytendays.co/txt">
            <img
              className="cursor-pointer"
              src={copyLogo}
              alt="copy"
            />
          </Copy>
          <ShareButton
            url={facebookShareLink}
            imageSrc={facebookLogo}
            label="facebook"
          />
          <ShareButton
            url={emailShareLink}
            imageSrc={emailLogo}
            label="email"
          />
          <ShareButton
            url={twitterShareLink}
            imageSrc={twitterLogo}
            label="twitter"
          />
        </div>
      </div>
    </div>
  </div>
}
