import { type FC } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import { displayCurrency } from '@lib/services'
import { AccountTabs, Card, loadSchedules, Variant, MTDtracking } from '@shamaazi/mytennights'
import { Button, CircleChecked, CircleExclamation, Page, Spinner } from '@lib/components'
import { MTDHeader } from '~/components/MTDHeader'

import { useAuth } from '@lib/hooks'
import { ScheduleListTestIds } from '@lib/testing'

export const ScheduleList: FC = () => {
  const { data: schedules, isLoading, isError } = useQuery('schedules', async () => loadSchedules('mtd'))
  const { user } = useAuth()

  const ScheduleCards = (schedules ?? []).map((s, i) => {
    const scheduleNumber = (schedules?.length ?? 0) - i
    return <Card key={s.scheduleID} variant="mtd" className="flex flex-col font-medium">
      <section className="p-10 border-b border-mtd-gray-200">
        <h2 className="text-lg text-mtd-blue">Schedule #{scheduleNumber}</h2>
        <p className="mt-4 text-xs">
          Created at {s.created.toLocaleString(DateTime.TIME_24_SIMPLE)} on {s.created.toLocaleString(DateTime.DATE_MED)}
        </p>
      </section>
      <section className="p-10 border-b border-mtd-gray-200">
        <div className="items-center grid gap-y-4 gap-x-6 grid-cols-auto-fr">
          <span className="text-sm">Charity</span>
          <span className="text-mtd-blue">{s.charity}</span>
          <span className="text-sm">Total</span>
          <span className="text-mtd-blue">{displayCurrency({ amount: s.totalAmount, currency: s.currency })}</span>
        </div>
      </section>
      <section className="flex flex-col flex-1 p-10">
        <div className="flex items-center flex-1 mb-6">
          <CircleChecked className="w-8 h-8 mr-4 text-mtd-blue" />
          <span>
            {s.completedCount === 0 && s.failedCount === 0 && <>You&apos;re all set up and ready<br /></>}
            {s.completedCount} of {s.numberOfPayments} donations completed
          </span>
        </div>
        {s.failedCount > 0 && <div className="flex items-center mb-6">
          <CircleExclamation className="w-8 h-8 mr-4 text-mtd-red" />
          <span>{s.failedCount} {s.failedCount > 1 ? 'donations' : 'donation'} failed</span>
        </div>}
        <Link to={`/account/schedule/${s.scheduleID}`} className="items-center w-full">
          <Button
            onClick={() => MTDtracking.viewEdit(user?.email)}
            data-test-id={ScheduleListTestIds.scheduleViewEdit_ + s.scheduleID}
            className="w-full" variant="mtd-inverted">VIEW / EDIT</Button>
        </Link>
      </section>
    </Card>
  })

  return <main>
    <MTDHeader />
    <Page skinny className="mt-10">
      <AccountTabs active="schedules" variant={Variant.mtd} tracking={MTDtracking} />
      {isLoading && <Spinner className="mx-auto text-mtd-blue" />}
      {!isLoading && isError && <h2 className="heading-lg">We were unable to load your schedules, please try again</h2>}
      {!isLoading && !isError && (schedules ?? []).length === 0 && <p>
        You have no schedules yet. Create one by donating <Link to="/">here</Link>.
      </p>}
      <div className="justify-center mb-20 grid gap-10 sm:grid-cols-2">
        {ScheduleCards}
      </div>
    </Page>
  </main>
}
