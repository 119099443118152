import { type FC, useEffect } from 'react'
import { MTDHeader } from '~/components/MTDHeader'
import { GivematchShareContent, useCheckoutPlan, useSharePageDetails } from '@shamaazi/mytennights'
import { ShareContent } from '~/components/Share/shareContent'
import { Variant } from '@lib/components'

export const Share: FC = () => {
  const { resetPlan } = useCheckoutPlan()
  const { sharePageDetails: details } = useSharePageDetails()
  const showGivematch = details.matchPotBalance >= details.matchedAmount

  useEffect(resetPlan, [resetPlan])

  return <>
    <MTDHeader overlay />
    {!showGivematch && <ShareContent />}
    {showGivematch && <GivematchShareContent details={details} platform={Variant.mtd}/>}
  </>
}
